import { useTranslation } from "react-i18next";

import location from "../../assets/svg/location.svg";
import phone from "../../assets/svg/phone.svg";
import email from "../../assets/svg/email.svg";

import facebook from "../../../src/assets/svg/social-media/facebook-white.svg";
import group from "../../../src/assets/svg/social-media/group-white.svg";
import instagram from "../../../src/assets/svg/social-media/instagram-white.svg";
import youtube from "../../../src/assets/svg/social-media/youtube-white.svg";
import linkedin from "../../../src/assets/svg/social-media/linkedin-white.svg";
import x from "../../../src/assets/svg/social-media/x-white.svg";
import tiktok from "../../../src/assets/svg/social-media/tiktok-white.svg";

import PrivacyPDF from "../../assets/documents/POLITICAS-PRIVACIDAD.pdf";
import ConditionsPDF from "../../assets/documents/TERMINOS-Y-CONDICIONES.pdf";

export const Footer = () => {
  const { t } = useTranslation();

  const handleOpenPDF = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  const footer_data = [
    {
      title: "Legal",
      data: [
        {
          description: t("privacy-policies"),
          onclick: () => handleOpenPDF(PrivacyPDF),
        },
        {
          description: t("terms-conditions"),
          onclick: () => handleOpenPDF(ConditionsPDF),
        },
      ],
    },
    {
      title: t("company-info"),
      data: [
        {
          description: t("direction"),
        },
        {
          description: "+16194716097",
        },
        {
          description: "drz@zplendid.com",
        },

        {
          description: "info@zplendid.com",
        },
      ],
    },
  ];

  const social_media = [
    {
      name: "Facebook",
      svg: facebook,
      href: "https://www.facebook.com/DrZavalza?sfnsn=scwspwa&mibextid=RUbZ1f",
    },
    {
      name: "Facebook Group",
      svg: group,
      href: "https://www.facebook.com/groups/856770857736807/?mibextid=A7sQZp&rdid=sTA7A71Pd6zNiTgM&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fg%2FybcjpFo3XZSmK5g7%2F%3Fmibextid%3DA7sQZp",
    },
    {
      name: "Instagram",
      svg: instagram,
      href: "https://www.instagram.com/dr_zavalza?utm_source=qr&igsh=MXIzY3Fpd25zYTZ4bA%3D%3D",
    },
    {
      name: "Youtube",
      svg: youtube,
    },
    {
      name: "Linkedin",
      svg: linkedin,
    },
    {
      name: "X",
      svg: x,
    },
    {
      name: "Tik Tok",
      svg: tiktok,
      href: "https://www.tiktok.com/@drfranciscozavalza?_t=8p0OZgmaJks&_r=1",
    },
  ];

  return (
    <footer className="bg-[#191B3C] h-full bg-variable-collection-RGB-25-27-60-texto md:px-10 lg:px-40 md:py-16 py-7 px-7  text-white">
      <div className="md:flex justify-between pb-6 md:border-b border-white">
        <div className="w-full">
          <p className="md:italic md:font-light text-base md:text-left">
            Lorem ipsum dolor sit amet consectetur. <br />
            Augue vel posuere in ultricies in etiam maecenas <br />
            Varius nisl commodo nec ultrices.
          </p>
        </div>
        {/*MOBILE*/}
        <div className="md:hidden mt-[18px] space-y-[18px] flex flex-col justify-center w-full items-center">
          <img className="w-5" alt="location" src={location}></img>
          <p>{t("direction")}</p>
          <img className="w-5" alt="phone" src={phone}></img>
          <p>Tel: +16194716097</p>
          <img className="w-5" alt="email" src={email}></img>
          <div>
            <p>info@Zplendid.com</p>
            <p>drz@zplendid.com</p>
          </div>

          <p className="font-semibold">Follow us</p>
          <div className="flex space-x-3">
            {social_media.map((item) => (
              <button
                key={"social media" + item.name}
                onClick={() => {
                  if (item.href) {
                    window.open(item.href);
                  }
                }}
              >
                <img
                  className="relative w-[29px] h-[29px]"
                  alt={item.name}
                  src={item.svg}
                />
              </button>
            ))}
          </div>
        </div>
        {/*DESKTOP*/}
        <div className="hidden md:flex justify-between w-full">
          {footer_data.map((item, index) => (
            <div key={"footer data" + index} className="text-left space-y-6">
              <p className="text-xl font-semibold">{item.title}</p>
              {item.data.map((data, index) => (
                <div
                  onClick={data.onclick}
                  key={"description" + index}
                  className={`space-y-4 ${data.onclick && "cursor-pointer"}`}
                >
                  <div>
                    {data.description.split("\n").map((line, index) => (
                      <p className="text-base font-light" key={index}>
                        {line}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <p className="hidden md:block pt-10 text-base font-light">
        Copyright © Zplendid 2024
      </p>
    </footer>
  );
};
