import { ContactUsButton } from "../shared/ContactUsButton";

import past from "../../assets/img/testimonials/past.png";
import future from "../../assets/img/testimonials/future.png";

import testimonial2past from "../../assets/img/testimonials/testimonial2past.jpg";
import testomional2present from "../../assets/img/testimonials/testimional2present.jpg";

import testimonial3past from "../../assets/img/testimonials/testimonial3past.png";
import testimional3present from "../../assets/img/testimonials/testimional3present.png";

import testimonial4past from "../../assets/img/testimonials/testimional4past.jpg";
import testimional4present from "../../assets/img/testimonials/testimional4present.jpg";

import testimonial5past from "../../assets/img/testimonials/kimberly1.jpg";
import testimional5present from "../../assets/img/testimonials/kimberly2.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";
import FadeUp from "../shared/FadeUp";

export const Testimonials = () => {
  const { t, i18n } = useTranslation();

  const language = i18n.language;

  const testimonials = [
    {
      description: {
        en: "In September 2021, I had a consultation with Dr. Z and began my journey. I followed his pre-op diet, booked my flights, and I was ready for surgery after struggling with weight loss and severe chronic gastritis all my life. Everything went smoothly. My highest weight was 298, surgery weight was 252, and now I’m 134.\n In 2023, I returned for another procedure, and Dr. Z took the time to take a picture with me and catch up. In 2024, I’ve had some unrelated health issues, but Dr. Z has been helping me figure them out since they’re GI-related. He’s hands down one of the best doctors I’ve ever had.",
        "es-US":
          "En septiembre de 2021, tuve una consulta con el Dr. Z y comencé mi viaje. Seguí su dieta preoperatoria, reservé mis vuelos y estaba listo para la cirugía después de luchar con la pérdida de peso y una gastritis crónica severa toda mi vida. Todo salió bien. Mi peso máximo fue 298, el peso al momento de la cirugía fue 252 y ahora estoy en 134.\n En 2023, regresé para otro procedimiento, y el Dr. Z se tomó el tiempo para tomarse una foto conmigo y ponernos al día. En 2024, he tenido algunos problemas de salud no relacionados, pero el Dr. Z ha estado ayudándome a resolverlos ya que son problemas gastrointestinales. Sin lugar a dudas, es uno de los mejores médicos que he tenido.",
      },
      name: "Christina Marie",
      author: "",
      past_img: past,
      future_img: future,
    },
    {
      description: {
        en: "I struggled with my weight from a young age, starting after traumatic events at age 5. Despite trying various diets, the weight always returned. After my dad passed in 2021, I decided to take control of my life. On August 19, 2022, at 57 years old and 210 lbs, I underwent surgery alone.\n Though scared, the support of Dr. Z and the nurses helped me through. Despite a post-surgery complication, I recovered well and reached my goal weight, feeling amazing and medication-free. I now enjoy an active life with my family, feeling healthier than ever.",
        "es-US":
          "Luché con mi peso desde joven, comenzando después de eventos traumáticos a los 5 años. A pesar de intentar varias dietas, el peso siempre volvía. Después de que mi papá falleció en 2021, decidí tomar control de mi vida. El 19 de agosto de 2022, a los 57 años y con 210 libras, me sometí a una cirugía sola.\n Aunque tenía miedo, el apoyo del Dr. Z y las enfermeras me ayudó. A pesar de una complicación después de la cirugía, me recuperé bien y alcancé mi peso objetivo, sintiéndome increíble y sin necesidad de medicamentos. Ahora disfruto de una vida activa con mi familia, sintiéndome más saludable que nunca.",
      },
      name: "Dawn Hamilton",
      author: "",
      past_img: testimonial2past,
      future_img: testomional2present,
    },
    {
      description: {
        en: "Dr Zavalza was recommended to me by 2 friends. I never met a surgeon that is so skilled, knowledgeable, and caring. Doing the surgery with him was probably one of the best decisions of my life. I felt completely safe every step of the way  and trusted him completely.\n He always answered my toughest questions, and even after surgery, he was always available for me whenever I needed him. I had RNY Bypass and Gallbladder removal. Recovery was seamless, and I'm so happy with my results and new life 1.5 years post op. I would recommend Dr Zavalza to everyone who is ready to change their lives.",
        "es-US":
          "El Dr. Zavalza me fue recomendado por dos amigos. Nunca había conocido a un cirujano tan hábil, conocedor y atento. Hacer la cirugía con él fue probablemente una de las mejores decisiones de mi vida. Me sentí completamente segura en cada paso del camino y confiaba en él plenamente.\n Siempre respondió a mis preguntas más difíciles, y incluso después de la cirugía, siempre estuvo disponible para mí cuando lo necesitaba. Me hice un Bypass Gástrico RNY y una extirpación de la vesícula biliar. La recuperación fue perfecta, y estoy muy feliz con mis resultados y mi nueva vida 1.5 años después de la operación. Recomendaría al Dr. Zavalza a todos los que estén listos para cambiar sus vidas.",
      },
      name: "Irina",
      author: "",
      past_img: testimonial3past,
      future_img: testimional3present,
    },
    {
      description: {
        en: "Good day everyone!! Hope everyone is having a fabulous day. I am wanting to get some information. I have finally made my weight goal. Went from being 228lbs to 135lbs. Now I am very self conscious with my breast and assess skin. So I don’t know if Dr Z group can give recommendations on who to use for correction.\nIf you have used someone can you recommend them to me and all the information. Pictures would be great. Where you stay and how long your had to stay and recovery time. I am ready to take the next step.",
        "es-US":
          "¡Buen día a todos! Espero que todos estén teniendo un día fabuloso. Quisiera obtener un poco de información. Finalmente he alcanzado mi meta de peso. Pasé de pesar 228 libras a 135 libras. Ahora me siento muy acomplejada con mis senos y el exceso de piel. No sé si el grupo del Dr. Z puede darme recomendaciones sobre a quién acudir para la corrección.\nSi han utilizado a alguien, ¿pueden recomendarlo y brindarme toda la información? Sería genial si tienen fotos. ¿Dónde se alojaron, cuánto tiempo tuvieron que quedarse y cuál fue el tiempo de recuperación? Estoy lista para dar el siguiente paso.",
      },
      name: "Desiree",
      author: "",
      past_img: testimonial4past,
      future_img: testimional4present,
    },
    {
      description: {
        en: "I am forever grateful to Dr. Zavalza and his team.  I felt comfortable from the first video consult with my decision to have RNY bypass surgery on 2/9/2024.  I weighed 200 lbs the day of surgery.  I now weigh 145 lbs, my goal is 135 or maybe less.  I went from a size 16/18 in jeans to now a size 6/8!  I received excellent care by Dr. Zavalza and the staff at the hospital.\nThe aftercare is amazing too.  I can contact Dr. Zavalza with any questions or concerns and he always responds promptly.  I highly recommend Dr. Zavalza.",
        "es-US":
          "Estoy eternamente agradecida con el Dr. Zavalza y su equipo. Me sentí cómoda desde la primera consulta por video con mi decisión de hacerme la cirugía de bypass gástrico el 9/2/2024. Pesaba 200 libras el día de la cirugía. Ahora peso 145 libras, y mi objetivo es llegar a 135 o quizás menos. Pasé de usar jeans talla 16/18 a una talla 6/8. Recibí un excelente cuidado por parte del Dr. Zavalza y el personal del hospital.\nEl cuidado posterior también es increíble. Puedo contactar al Dr. Zavalza con cualquier pregunta o inquietud, y él siempre responde de manera rápida. Recomiendo ampliamente al Dr. Zavalza.",
      },
      name: "Kimberly Bishop",
      author: "",
      past_img: testimonial5past,
      future_img: testimional5present,
    },
    {
      description: {
        en: "I recently underwent a bypass surgery performed by Dr. Zavalza, and I cannot express enough how grateful I am for the exceptional care I received. From the initial consultation to the follow-up appointments three weeks after the procedure, Dr. Zavalza displayed an incredible level of expertise, compassion, and professionalism.\nThe surgery itself was a success, and I’m already feeling a significant improvement in my overall health. Dr. Zavalza attention to detail and thorough explanations of each step in the process made me feel confident and well-informed throughout. The recovery period has been smoother than I expected, and I attribute that to Dr. Zavalza precise and careful work.\nMoreover, Dr. Zavalza bedside manner is truly remarkable. He always took the time to answer my questions, no matter how minor, and his genuine concern for my well-being was evident in every interaction. The care I received from him and his entire team was outstanding.\nI highly recommend Dr. Zaval to anyone in need of bariatric care. His skill and dedication have made a profound difference in my life, and I am deeply thankful for the positive experience.",
        "es-US":
          "Recientemente me sometí a una cirugía de bypass realizada por el Dr. Zavalza, y no puedo expresar lo suficiente lo agradecida que estoy por la atención excepcional que recibí. Desde la consulta inicial hasta las citas de seguimiento tres semanas después del procedimiento, el Dr. Zavalza mostró un nivel increíble de experiencia, compasión y profesionalismo.\nLa cirugía en sí fue un éxito, y ya estoy sintiendo una mejora significativa en mi salud en general. La atención al detalle del Dr. Zavalza y sus explicaciones detalladas de cada paso del proceso me hicieron sentir confiada y bien informada en todo momento. El período de recuperación ha sido más suave de lo que esperaba, y atribuyo eso al trabajo preciso y cuidadoso del Dr. Zavalza.\nAdemás, el trato del Dr. Zavalza es verdaderamente notable. Siempre se tomaba el tiempo para responder a mis preguntas, por más pequeñas que fueran, y su preocupación genuina por mi bienestar era evidente en cada interacción. La atención que recibí de él y de todo su equipo fue excepcional.\nRecomiendo altamente al Dr. Zavalza a cualquier persona que necesite atención bariátrica. Su habilidad y dedicación han marcado una diferencia profunda en mi vida, y estoy profundamente agradecida por la experiencia tan positiva.",
      },
      name: "Paola",
      author: "From Florida",
      past_img: null,
      future_img: null,
    },
  ];

  return (
    <div
      id="testimonials"
      className="flex flex-col items-center pt-[111px] bg-[#edf3fc] p-7 md:p-20 px-12 xl:px-52"
    >
      <div className="flex flex-col items-center relative w-full flex-[0_0_auto]">
        <div className="flex items-center justify-center gap-2.5 relative w-full flex-[0_0_auto]">
          <FadeUp>
            <div className="font-semibold text-[40px] text-[#5E51E9]">
              {t("patient-testimonials")}
            </div>
          </FadeUp>
        </div>

        <div className="mt-12 md:mt-[60px] w-full">
          <Swiper
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={30}
            slidesPerView={1}
            navigation
            autoplay={{ delay: 9000, disableOnInteraction: false }}
            pagination={{ clickable: true }}
            className="flex flex-col justify-center items-center bg-white w-full  rounded-[29px] shadow-xl"
          >
            {testimonials.map((item, index) => {
              const description =
                item.description[language] || item.description["en"];
              return (
                <SwiperSlide key={index}>
                  <div className="px-4 md:px-7 py-10 mb-8">
                    <div className="flex flex-col items-center max-w-full ">
                      <div className="md:flex items-start md:space-x-8 space-y-8 md:space-y-0">
                        {item.past_img && item.future_img && (
                          <div className="relative w-full flex items-center md:flex-row justify-center md:justify-end space-y-8 md:space-y-8">
                            <div
                              className="w-full bg-gray-200 max-h-[200px] md:max-h-[363px] md:w-[247px] md:h-[363px] border border-gray-100 rounded-[29px] bg-contain bg-no-repeat bg-center"
                              style={{
                                backgroundImage: `url(${item.past_img})`,
                              }}
                              alt={"past" + index}
                            >
                              <img
                                loading="lazy"
                                srcSet={item.past_img}
                                className="opacity-0"
                                alt={"past" + index}
                              />
                            </div>
                            <div
                              className="w-full bg-gray-200 max-h-[200px] md:w-[247px] md:max-h-[363px] md:h-[363px] border border-gray-100 rounded-[29px] md:mt-[64px] md:-ml-[24px] bg-contain bg-no-repeat bg-center"
                              style={{
                                backgroundImage: `url(${item.future_img})`,
                              }}
                              alt={"future" + index}
                            >
                              <img
                                loading="lazy"
                                srcSet={item.future_img}
                                className="opacity-0"
                                alt={"future" + index}
                              />
                            </div>
                          </div>
                        )}

                        <div className="flex w-full flex-col">
                          <div className="space-y-4">
                            {description.split("\n").map((line, index) => (
                              <p
                                className="text-left text-base leading-6 text-neutral-800 max-md:max-w-full space-y-6"
                                key={index}
                              >
                                {line}
                              </p>
                            ))}
                          </div>
                          <div className="flex gap-4 items-center self-start mt-2 text-center">
                            <div className="flex flex-col items-start self-stretch my-auto">
                              <div className="text-lg font-medium text-slate-800">
                                {item.name}
                              </div>
                              <div className="mt-1 text-base text-indigo-600">
                                {item.author}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="mt-[70px]">
          <FadeUp>
            <ContactUsButton />
          </FadeUp>
        </div>
      </div>
    </div>
  );
};
