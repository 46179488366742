import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          home: "Home",
          about: "About",
          "about-zplendid": "About Zplendid",
          "about-desc":
            "OUR DEDICATED TEAM OF BARIATRIC SURGEONS ARE COMMITTED TO CONQUERING OBESITY THROUGH WEIGHT LOSS SURGERY",
          services: "Servicios",
          "our-services": "Our Services",
          "bariatric-surgery": "Bariatric surgery",
          "bariatric-surgery-desc":
            "<span className='text-[#191b3c]'>It is a <b>safe and effective gastric surgery</b> procedure that<br />encourages weight loss by <b>reducing the stomach up to 85%.</b><br />This procedure is performed without hampering the normal<br />digestive process.</span> <p className='text-left text-lg leading-6'><br/><span className='text-[#191b3c]'>We use the latest laparoscopic techniques resulting in <br />minimal incisions and a shorter recovery period. <br />Gastric sleeve surgery is suitable for people who have failed <br /> to lose weight through lifestyle changes or exercise.</span>",
          "plastic-surgery": "Plastic surgery",
          "plastic-surgery-desc":
            "<p className='text-left text-lg leading-6'><span className='text-[#191b3c]'>Lorem ipsum dolor sit amet consectetur. In magna amet <br />vivamus leo amet nibh nec. Amet nam gravida eu imperdiet non vitae nec habitasse. Id pellentesque mi tortor enim quis urna nunc facilisis. Posuere leo quisque condimentum <br />congue quis amet ullamcorper mattis.</span></p>",
          doctors: "Doctors",
          "our-doctors": "Our Doctors",
          "view-cv": "View Certificates and cv",
          testimonials: "Testimonials",
          "patient-testimonials": "Patient Testimonials",
          "contact-us": "Contact us",
          "contact-us-button": "Contact Us",
          "contact-us-title": "Get in touch to book your first appointment",
          name: "Name",
          phone: "Phone",
          email: "Email",
          message: "Message",
          loading: "Loading",
          "message-sucess": "Message submitted successfully!",
          "submit-now": "Submit Now",
          "company-info": "Company Info",
          "direction": "Zplendid LLC, 530 S 8th St, Las Vegas Nevada,\nZip Code 89101",
          "privacy-policies": "Privacy Notice",
          "terms-conditions": "Terms and Conditions",
        },
      },
      es: {
        translations: {
          home: "Inicio",
          about: "Acerca de",
          "about-zplendid": "Acerca de Zplendid",
          "about-desc":
            "Nuestro equipo dedicado de cirujanos bariátricos está comprometido a vencer la obesidad a través de la cirugía de pérdida de peso.",
          services: "Servicios",
          "our-services": "Nuestros Servicios",
          "bariatric-surgery": "Cirugía bariátrica",
          "bariatric-surgery-desc":
            "<span className='text-[#191b3c]'>Es un procedimiento de <b>cirugía gástrica segura y eficaz</b> que<br />fomenta la pérdida de peso al <b>reducir el estómago hasta en un 85%</b>.<br />Este procedimiento se realiza sin afectar el <br />proceso digestivo normal.</span> <p className='text-left text-lg leading-6'><br/><span className='text-[#191b3c]'>Utilizamos las técnicas laparoscópicas más recientes, lo que resulta en <br />incisiones mínimas y un período de recuperación más corto. <br />La cirugía de manga gástrica es adecuada para personas que no han logrado <br />perder peso mediante cambios en el estilo de vida o ejercicio.</span>",
          "plastic-surgery": "Cirugía Plástica",
          "plastic-surgery-desc":
            "<p className='text-left text-lg leading-6'><span className='text-[#191b3c]'>Lorem ipsum dolor sit amet consectetur. In magna amet <br />vivamus leo amet nibh nec. Amet nam gravida eu imperdiet non vitae nec habitasse. Id pellentesque mi tortor enim quis urna nunc facilisis. Posuere leo quisque condimentum <br />congue quis amet ullamcorper mattis.</span></p>",
          doctors: "Doctores",
          "our-doctors": "Nuestros Doctores",
          "view-cv": "Ver Certificados y CV",
          testimonials: "Testimonios",
          "patient-testimonials": "Testimonios de Pacientes",
          "contact-us": "Contáctanos",
          "contact-us-button": "Contáctanos",
          "contact-us-title": "Ponte en contacto para reservar tu primera cita",
          name: "Nombre",
          phone: "Teléfono",
          email: "Correo",
          message: "Mensaje",
          loading: "Cargando",
          "message-sucess": "¡Mensaje enviado con éxito!",
          "submit-now": "Enviar Ahora",
          "company-info": "Información de la Empresa",
          "direction": "Zplendid LLC, 530 S 8th St, Las Vegas Nevada,\nCódigo Postal 89101",
          "privacy-policies": "Aviso de privacidad",
          "terms-conditions": "Términos y condiciones",
        },
      },
    },
    fallbackLng: "en",
    lng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
