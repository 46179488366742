import logo from "../../../src/assets/svg/logo.svg";
import menu from "../../../src/assets/svg/menu.svg";
import menuClose from "../../../src/assets/svg/x-gray.svg";

import facebook from "../../../src/assets/svg/social-media/facebook.svg";
import group from "../../../src/assets/svg/social-media/group.svg";
import instagram from "../../../src/assets/svg/social-media/instagram.svg";
import youtube from "../../../src/assets/svg/social-media/youtube.svg";
import linkedin from "../../../src/assets/svg/social-media/linkedin.svg";
import x from "../../../src/assets/svg/social-media/x.svg";
import tiktok from "../../../src/assets/svg/social-media/tiktok.svg";

import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";

export const Header = () => {
  const { t, i18n } = useTranslation();

  const navigation = [
    {
      name: t("home"),
      href: "home",
    },
    {
      name: t("about"),
      href: "about",
    },
    {
      name: t("services"),
      href: "services",
    },
    {
      name: t("doctors"),
      href: "doctors",
    },
    {
      name: t("testimonials"),
      href: "testimonials",
    },
    {
      name: t("contact-us"),
      href: "contact",
    },
  ];

  const social_media = [
    {
      name: "Facebook",
      svg: facebook,
      href: "https://www.facebook.com/DrZavalza?sfnsn=scwspwa&mibextid=RUbZ1f",
    },
    {
      name: "Facebook Group",
      svg: group,
      href: "https://www.facebook.com/groups/856770857736807/?mibextid=A7sQZp&rdid=sTA7A71Pd6zNiTgM&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2Fg%2FybcjpFo3XZSmK5g7%2F%3Fmibextid%3DA7sQZp",
    },
    {
      name: "Instagram",
      svg: instagram,
      href: "https://www.instagram.com/dr_zavalza?utm_source=qr&igsh=MXIzY3Fpd25zYTZ4bA%3D%3D",
    },
    {
      name: "Youtube",
      svg: youtube,
    },
    {
      name: "Linkedin",
      svg: linkedin,
    },
    {
      name: "X",
      svg: x,
    },
    {
      name: "Tik Tok",
      svg: tiktok,
      href: "https://www.tiktok.com/@drfranciscozavalza?_t=8p0OZgmaJks&_r=1",
    },
  ];

  const onClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [showMenu, setShowMenu] = useState(false);

  return (
    <header className="xl:flex w-full justify-between my-6 py-4 space-y-4">
      <div className="flex w-full justify-between md:justify-start md:gap-[100px] relative items-center space-y-4">
        <img
          className="relative w-[166.39px] h-[40.71px]"
          alt="logo"
          src={logo}
        />
        <div className="hidden md:flex space-x-8 font-semibold md:items-center md:justify-end">
          {navigation.map((item) => (
            <button
              key={"navigate" + item.href}
              onClick={() => onClickScroll(item.href)}
              className="text-[#191B3C] hover:text-[#5E51E9]"
            >
              {item.name}
            </button>
          ))}
          <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-full">
            <button
              onClick={() => changeLanguage("en")}
              className="text-ablue-900 whitespace-nowrap text-base font-bold text-agray-500 hover:text-ablue-500 mr-2"
            >
              EN{" "}
            </button>
            <p className="whitespace-nowrap text-base font-bold text-agray-500 hover:text-agray-900">
              {" "}
              I{" "}
            </p>
            <button
              onClick={() => changeLanguage("es-US")}
              className="text-ablue-900 whitespace-nowrap text-base font-bold text-agray-500 hover:text-ablue-500 ml-2"
            >
              ES{" "}
            </button>
          </div>
        </div>
        <button onClick={() => setShowMenu(true)} className="md:hidden">
          <img src={menu} alt="menu"></img>
        </button>
      </div>
      {showMenu && (
        <MobileHeader
          navigation={navigation}
          changeLanguage={changeLanguage}
          social_media={social_media}
          setShowMenu={setShowMenu}
        />
      )}
      <div className="hidden md:flex space-x-[17px] items-center w-auto justify-center md:justify-end">
        {social_media.map((item) => (
          <button
            key={"social media" + item.name}
            onClick={() => {
              if (item.href) {
                window.open(item.href);
              }
            }}
          >
            <img
              className="relative w-[29px] h-[29px]"
              alt={item.name}
              src={item.svg}
            />
          </button>
        ))}
      </div>
      <div>
        {showMenu && (
          <div className="md:hidden fixed top-0 left-0 w-full h-full flex items-center justify-center z-40 overflow-auto bg-black bg-opacity-50" />
        )}
      </div>
    </header>
  );
};

const MobileHeader = ({
  navigation,
  changeLanguage,
  setShowMenu,
  social_media,
}) => {
  const onClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView(true);
    }
    setShowMenu(false);
  };
  return (
    <Fragment>
      <div className="absolute top-0 right-0 bg-white z-50 w-[312px] md:hidden !mt-0 flex flex-col items-start h-full p-6 font-semibold">
        <div className="relative h-full w-full">
          <div className="flex w-full justify-between mb-[80px]">
            <img
              className="relative w-[166.39px] h-[40.71px]"
              alt="logo"
              src={logo}
            />
            <button onClick={() => setShowMenu(false)} className="md:hidden">
              <img src={menuClose} alt="menuClose"></img>
            </button>
          </div>
          <div className="space-y-4 flex flex-col items-start text-left">
            {navigation.map((item) => (
              <button
                key={"navigate" + item.href}
                onClick={() => {
                  onClickScroll(item.href);
                }}
                className="text-[#191B3C] text-lg hover:text-[#5E51E9]"
              >
                {item.name}
              </button>
            ))}
            <div className="flex items-center justify-end lg:flex-1 lg:w-full">
              <button
                onClick={() => changeLanguage("en")}
                className="text-ablue-900 whitespace-nowrap text-base font-bold text-agray-500 hover:text-ablue-500 mr-2"
              >
                EN{" "}
              </button>
              <p className="whitespace-nowrap text-base font-bold text-agray-500 hover:text-agray-900">
                {" "}
                I{" "}
              </p>
              <button
                onClick={() => changeLanguage("es-US")}
                className="text-ablue-900 whitespace-nowrap text-base font-bold text-agray-500 hover:text-ablue-500 ml-2"
              >
                ES{" "}
              </button>
            </div>
          </div>

          <div className="absolute w-full flex bottom-0 space-x-[17px] items-center w-auto justify-center md:justify-end">
            {social_media.map((item) => (
              <button
                key={"social media" + item.name}
                onClick={() => {
                  if (item.href) {
                    window.open(item.href);
                  }
                }}
              >
                <img
                  className="relative w-[29px] h-[29px]"
                  alt={item.name}
                  src={item.svg}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
