import { useTranslation } from "react-i18next";
import bariatricSurgery from "../../assets/img/bariatric-surgery.png";
import plasticSurgery from "../../assets/img/plastic-surgery.png";
import { ContactUsButton } from "../shared/ContactUsButton";
import FadeUp from "../shared/FadeUp";

export const OurServices = () => {
  const { t } = useTranslation();

  return (
    <div
      id="services"
      className="bg-[#edf3fc] md:px-10 w-full xl:px-28 py-20 pt-[119px]"
    >
      <div className="px-7 flex flex-col items-start gap-20 relative ">
        <FadeUp>
          <div className="font-semibold text-[40px] text-[#5E51E9]">
            {t("our-services")}
          </div>
        </FadeUp>

        <div className="md:flex flex-col justify-center w-full space-y-6">
          <FadeUp>
            <div className="md:flex xl:space-x-20 space-y-6 md:space-y-0 justify-center">
              <div className="relative">
                <img
                  className="md:w-[475px] md:h-[363px] object-cover rounded-xl"
                  alt="bariatricSurgery_img"
                  src={bariatricSurgery}
                />
              </div>

              <div className="space-y-6">
                <div className="font-bold text-3xl text-[#191B3C] text-left">
                  {t("bariatric-surgery")}
                </div>

                <div
                  className="text-left text-lg font-normal"
                  dangerouslySetInnerHTML={{
                    __html: t("bariatric-surgery-desc"),
                  }}
                />
              </div>
            </div>
          </FadeUp>
          <FadeUp>
            <div className="md:flex xl:space-x-20 justify-center space-y-6 md:space-y-0">
              <div className="md:flex flex-col space-y-6 md:w-[455px]">
                <div className="font-bold text-3xl text-[#191B3C] text-left">
                  {t("plastic-surgery")}
                </div>
                <div
                  className="text-left text-lg font-normal"
                  dangerouslySetInnerHTML={{
                    __html: t("plastic-surgery-desc"),
                  }}
                />
              </div>
              <div className="">
                <img
                  className="md:w-[475px] md:h-[363px]8"
                  alt="Unsplash jgmbzypofpc"
                  src={plasticSurgery}
                />
              </div>
            </div>
          </FadeUp>
        </div>

        <div className="w-full justify-center flex ">
          <FadeUp>
            <ContactUsButton />
          </FadeUp>
        </div>
      </div>
    </div>
  );
};
