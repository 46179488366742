import { useState, useRef, Fragment } from "react";

import DrZavalza from "../../assets/img/Dr-Zavalza.png";
import DrKarla from "../../assets/img/Dra-Karla.png";
import DrMario from "../../assets/img/Dr-Mario.png";

import KarlaCB from "../../assets/img//certificates/Karla/Karla-cb.jpg";
import KarlaCertificate from "../../assets/img/certificates/Karla/Karla-certificate.jpg";
import KarlaDiploma from "../../assets/img//certificates/Karla/Karla-diploma.jpg";
import KarlaCV1 from "../../assets/img/certificates/Karla/Karla-CV-1.jpg";
import KarlaCV2 from "../../assets/img/certificates/Karla/Karla-CV-2.jpg";
import KarlaCV3 from "../../assets/img/certificates/Karla/Karla-CV-3.jpg";

import MarioColegio from "../../assets/img//certificates/Mario/Mario-Colegio-medico.jpg";
import MarioCV1 from "../../assets/img/certificates/Mario/CV-Dr.-Mario-Mora-2024-page1.jpg";
import MarioCV2 from "../../assets/img/certificates/Mario/CV-Dr.-Mario-Mora-2024-page2.jpg";
import MarioCV from "../../assets/img/certificates/Mario/Mario-CV.jpg";
import MarioCierre from "../../assets/img/certificates/Mario/Cierre-02.png";
import MarioCierre4 from "../../assets/img/certificates/Mario/Cierre-04.png";
import MarioCierre5 from "../../assets/img/certificates/Mario/Cierre-05.png";
import MarioCierre6 from "../../assets/img/certificates/Mario/Cierre-06.png";
import MarioCierre7 from "../../assets/img/certificates/Mario/Cierre-07.png";

import ZavalzaRecertificate1 from "../../assets/img/certificates/Zavalza/Recertificacion1.jpg";
import ZavalzaRecertificate2 from "../../assets/img/certificates/Zavalza/Recertificacion2.jpg";
import ZavalzaCedulas1 from "../../assets/img/certificates/Zavalza/CEDULAS-ZAVALZA1.jpg";
import ZavalzaCedulas2 from "../../assets/img/certificates/Zavalza/CEDULAS-ZAVALZA2.jpg";
import ZavalzaCV1 from "../../assets/img/certificates/Zavalza/CV-DR.-ZAVALZA1.jpg";
import ZavalzaCV2 from "../../assets/img/certificates/Zavalza/CV-DR.-ZAVALZA2.jpg";
import ZavalzaCV3 from "../../assets/img/certificates/Zavalza/CV-DR.-ZAVALZA3.jpg";
import ZavalzaCV4 from "../../assets/img/certificates/Zavalza/CV-DR.-ZAVALZA4.jpg";
import ZavalzaCV5 from "../../assets/img/certificates/Zavalza/CV-DR.-ZAVALZA5.jpg";
import ZavalzaRecertificate3 from "../../assets/img/certificates/Zavalza/Recertificacion3.jpg";
import ZavalzaRecertificate4 from "../../assets/img/certificates/Zavalza/Recertificacion4.jpg";
import ZavalzaCV6 from "../../assets/img/certificates/Zavalza/Zavalza-CV.jpg";
import ZavalzaCV7 from "../../assets/img/certificates/Zavalza/Zavalza-CV2.jpg";

import arrowDown from "../../assets/svg/arrow-down.svg";
import arrowUp from "../../assets/svg/arrow-up.svg";
import arrowRight from "../../assets/svg/arrow-right.svg";

import slideLeft from "../../assets/svg/slide-left.svg";
import slideRight from "../../assets/svg/slide-right.svg";

import Modal from "../shared/Modal";
import { useTranslation } from "react-i18next";
import FadeUp from "../shared/FadeUp";

export const OurDoctors = () => {
  const { t, i18n } = useTranslation();

  const doctors = [
    {
      name: "Francisco Zavalza",
      position: "MD",
      description: {
        en: "Top ranked bariatric surgeon in Mexico that has conducted more than 10,000 surgeries for the control of obesity and metabolic diseases.",
        "es-US":
          "Cirujano bariátrico mejor clasificado en México, que ha realizado más de 10,000 cirugías para el control de la obesidad y enfermedades metabólicas.",
      },
      img: DrZavalza,
      documents: [
        {
          img: ZavalzaRecertificate1,
          type: "img",
        },
        {
          img: ZavalzaRecertificate2,
          type: "img",
        },
        {
          img: ZavalzaCedulas1,
          type: "img",
        },
        {
          img: ZavalzaCedulas2,
          type: "img",
        },
        {
          img: ZavalzaCV1,
          type: "img",
        },
        {
          img: ZavalzaCV2,
          type: "img",
        },
        {
          img: ZavalzaCV3,
          type: "img",
        },
        {
          img: ZavalzaCV4,
          type: "img",
        },
        {
          img: ZavalzaCV5,
          type: "img",
        },
        {
          img: ZavalzaRecertificate3,
          type: "img",
        },
        {
          img: ZavalzaRecertificate4,
          type: "img",
        },
        {
          img: ZavalzaCV6,
          type: "img",
        },
        {
          img: ZavalzaCV7,
          type: "img",
        },
      ],
    },
    {
      name: "Dra. Karla Rosales",
      position: "MD",
      description: {
        en: "Surgeon with double board certification by the Mexican Council of General Surgery with 4 years of experience in bariatric surgery and more than 1000 surgeries performed, committed to the dignified and personalized treatment of the patient, as well as constant updating on relevant topics to achieve excellence in the operating room.",
        "es-US":
          "Cirujana con doble certificación por el Consejo Mexicano de Cirugía General, con 4 años de experiencia en cirugía bariátrica y más de 1000 cirugías realizadas, comprometido con el trato digno y personalizado del paciente, así como con la actualización constante en temas relevantes para lograr la excelencia en el quirófano.",
      },
      img: DrKarla,
      documents: [
        {
          img: KarlaCB,
          type: "img",
        },
        {
          img: KarlaCertificate,
          type: "img",
        },
        {
          img: KarlaDiploma,
          type: "img",
        },
        {
          img: KarlaCV1,
          type: "img",
        },
        {
          img: KarlaCV2,
          type: "img",
        },
        {
          img: KarlaCV3,
          type: "img",
        },
      ],
    },
    {
      name: "Dr. Mario Mora",
      position: "MD",
      description: {
        en: "Plastic Surgeon with Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
        "es-US":
          "Cirujano Plástico con Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
      },
      img: DrMario,
      documents: [
        {
          img: MarioColegio,
          type: "img",
        },
        {
          img: MarioCV1,
          type: "img",
        },
        {
          img: MarioCV2,
          type: "img",
        },
        {
          img: MarioCV,
          type: "img",
        },
        {
          img: MarioCierre,
          type: "img",
        },
        {
          img: MarioCierre4,
          type: "img",
        },
        {
          img: MarioCierre5,
          type: "img",
        },
        {
          img: MarioCierre6,
          type: "img",
        },
        {
          img: MarioCierre7,
          type: "img",
        },
      ],
    },
  ];
  return (
    <div id="doctors" className="flex flex-col items-center pt-[79px] ">
      <div className="flex flex-col items-center gap-[70px] relative  flex-[0_0_auto]">
        <div className="flex items-center justify-center gap-2.5 relative  w-full flex-[0_0_auto]">
          <FadeUp>
            <div className="font-semibold text-[40px] text-[#5E51E9]">
              {t("our-doctors")}
            </div>
          </FadeUp>
        </div>
        <FadeUp>
          <div className="md:flex flex-col justify-center w-full md:px-0 xl:px-28">
            <div className="grid md:gap-x-[30px] lg:grid-cols-2 xl:grid-cols-3 px-7 gap-y-[30px] md:space-y-0">
              {doctors.map((item, index) => (
                <DoctorCard
                  key={"Doctor" + index}
                  doctor={item}
                  language={i18n.language}
                />
              ))}
            </div>
          </div>
        </FadeUp>
      </div>
    </div>
  );
};

const DoctorCard = ({ doctor, language }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const textRef = useRef(null);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div
      className={`flex min-w-[380px] ${
        !isExpanded && " h-[595px]"
      } justify-center gap-2.5 px-[30px] py-[35px] relative flex-1 grow bg-white rounded-[30px] shadow-[4px_4px_24px_#abaec240] transition-all duration-300 ease-in-out`}
    >
      <DoctorModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        documents={doctor.documents}
      />
      <div className="flex flex-col w-[332px] items-center gap-[15px] relative ml-[-6.00px] mr-[-6.00px]">
        <div className="flex items-center justify-center gap-2.5 relative w-full flex-[0_0_auto]">
          <div className="inline-flex h-[276px] items-center gap-2.5 relative flex-[0_0_auto]">
            <div className="relative w-[225px] h-[225px] bg-[#edf3fc] rounded-full overflow-hidden">
              <img
                className="absolute inset-0 w-full h-full object-cover"
                alt="Mask group"
                src={doctor.img}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[332px] items-center gap-[18px] relative flex-[0_0_auto]">
          <div className="inline-flex flex-col items-start gap-2 relative flex-[0_0_auto]">
            <div className="font-bold text-[32px] text-[#191B3C] text-center w-full">
              {doctor.name}
            </div>
            <div className="w-full text-center font-semibold text-2xl text-[#5E51E9]">
              {doctor.position}
            </div>
            <p
              ref={textRef}
              className={``}
              style={{
                display: isExpanded ? "" : "-webkit-box",
                WebkitLineClamp: isExpanded ? "none" : 3,
                WebkitBoxOrient: "vertical",
                overflow: isExpanded ? "visible" : "hidden",
                textOverflow: isExpanded ? "clip" : "ellipsis",
              }}
            >
              {doctor.description[language]}
            </p>
            {isExpanded && (
              <button
                onClick={openModal}
                className="uppercase flex w-full mt-6 justify-center items-center text-[#191B3C] text-lg font-bold space-x-1.5"
              >
                <p>{t("view-cv")}</p>
                <img
                  className="relative w-[18px] h-[18px]"
                  alt="Fluent ios arrow"
                  src={arrowRight}
                />
              </button>
            )}
          </div>
          <button onClick={handleClick}>
            <img
              className="relative w-8 h-8"
              alt="Fluent ios arrow"
              src={isExpanded ? arrowUp : arrowDown}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const DoctorModal = ({ isModalOpen, closeModal, documents }) => {
  return (
    <Modal
      className="mx-6 md:mx-0 bg-[#EDF3FC] rounded-lg shadow-lg p-[30px] md:p-[30px] relative"
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <div className="flex md:space-x-5 items-center">
        <DocumentSlider documents={documents} />
      </div>
    </Modal>
  );
};

const DocumentSlider = ({ documents }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? documents.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === documents.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Fragment>
      <div className="hidden md:flex space-x-5 items-center">
        {documents.length > 1 && (
          <img
            className="relative w-8 h-8 cursor-pointer"
            alt="slideLeft"
            src={slideLeft}
            onClick={handlePrev}
          />
        )}
        <img
          className="md:max-w-[90vw] md:max-h-[90vh] object-contain"
          src={documents[currentIndex].img}
          alt={`Certificate ${currentIndex + 1}`}
        />
        {documents.length > 1 && (
          <img
            className="relative w-8 h-8 cursor-pointer"
            alt="slideRight"
            src={slideRight}
            onClick={handleNext}
          />
        )}
      </div>
      <div className="md:hidden space-y-2 flex flex-col">
        <img
          className="md:max-w-[90vw] md:max-h-[90vh] object-contain"
          src={documents[currentIndex].img}
          alt={`Certificate ${currentIndex + 1}`}
        />
        <div className="flex space-x-5">
          {documents.length > 1 && (
            <img
              className="relative w-8 h-8 cursor-pointer"
              alt="slideLeft"
              src={slideLeft}
              onClick={handlePrev}
            />
          )}
          {documents.length > 1 && (
            <img
              className="relative w-8 h-8 cursor-pointer"
              alt="slideRight"
              src={slideRight}
              onClick={handleNext}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
